import React from 'react';
import { string } from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import loadable from '@loadable/component';

import { ALL_CARDS_ROUTE, CREATE_ROUTE, SUPPORT_PROBLEM } from 'constants/navigation';
import { LOYALTY_CARD_NAV, ADD_NAV, BUG } from 'constants/font-awesome';

import useStyles from './styles';

const LazyBanner = loadable(() => import('components/banners/in-app-banners/two'));
const Page = loadable(() => import('components/page'));
const ApplicationFooter = loadable(() => import('components/footer/application-footer'));
const CreateMediaView = loadable(() => import('views/loyalty-card/create-media'));
const Typography = loadable(() => import('@material-ui/core/Typography'));

const SuspendedCreateLoyaltyCardMediaPage = ({ passId = '' }) => {
  const {
    cmsJson: {
      pages: {
        createMedia,
      },
    },
  } = useStaticQuery(
    graphql`
      query  {
      cmsJson {
        pages {
          createMedia {
            title
            message
          }
        }
      }
    }`,
  );

  const { title, message } = createMedia;

  const styles = useStyles();
  return (
   <Page title="Create media" description={message}>
     <LazyBanner/>
     <Typography variant="h2" component="h1" className={styles.heading} align="center">
     {title}
     </Typography>
      <Typography variant="h4" component="p" className={styles.headingMessage} align="center">
      {message}
     </Typography>
     <CreateMediaView passId={passId}/>
     <ApplicationFooter
       navItems={[
         { route: ALL_CARDS_ROUTE, icon: LOYALTY_CARD_NAV, message: 'Cards' },
         { route: CREATE_ROUTE, icon: ADD_NAV, message: 'Add' },
         { route: SUPPORT_PROBLEM, icon: BUG, message: 'Get Support' },
       ]}
       currentItem="Add"
       />
   </Page>
  );
};

SuspendedCreateLoyaltyCardMediaPage.propTypes = { passId: string };

export default SuspendedCreateLoyaltyCardMediaPage;
